* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --color-primary: #0a103c;
  --color-secondary: #b3252c;
  --font-primary: "Open Sans", Helvetica, Arial, sans-serif;
}

*::selection {
  background-color: var(--color-primary);
  color: #fff !important;
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
  font-size: 62.5% !important; // 1rem = 10px, 10px/16px = 62.5%
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
  line-height: 1.5;
  color: #545454;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100%;
  background-color: #fff !important;
  overflow-x: hidden !important;
  // background-color: #F5F7FA !important;
}

.bg-sec-link{
  background: var(--color-secondary) !important;

  &:hover{
    transition: .2s all ease-in-out;
    background: var(--color-primary) !important;
    color: #fff !important;
    cursor: pointer;
  }
}

.header{
  background-color: #fff;
  padding-top: 1rem;
  padding-bottom: .5rem;

  @media only screen and (max-width: 990px) {
    display: none;
 }

  &_content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    &-link{
      width: 100%;
      font-family: "Open Sans", sans-serif;
      background: var(--color-primary);
      margin-top: 1rem;
      padding: 1rem 1.5rem;
      color: #fff;
      display: block;
      font-size: 1.4rem;
      line-height: 1.4;
      letter-spacing: 0;
      font-weight: 700;
      text-align: center;

      &:hover{
        text-decoration: none;
        transition: .2s all ease-in-out;
        background: var(--color-secondary);
        color: #fff !important;
        cursor: pointer;
      }

    //   @media only screen and (max-width: 1200px) {
    //     white-space: pre-wrap;
    //  }
    }
  }
}

.Navbar {
  position: relative;
  z-index: 100 !important;
  margin-top: 1rem;

  &_home {
    padding: 0 20rem;
    background-color: #f5f6fa;

    @media only screen and (min-width: 2200px) {
      padding: 0;
    }

    @media only screen and (max-width: 1800px) {
      padding: 0 7rem;
    }

    @media only screen and (max-width: 1600px) {
      padding: 0 7rem;
    }

    @media only screen and (max-width: 1200px) {
      padding: 0 2rem;
    }

    @media only screen and (max-width: 1100px) {
      padding: 0 .5rem;
    }

    @media only screen and (max-width: 1000px) {
      padding: 0;
    }

    @media only screen and (max-width: 990px) {
      padding: 0 2rem;
    }

    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
}

.navbar-toggler{
  float: right !important;
}

.to-hide-1400{

  @media only screen and (max-width: 1355px) {
    display: none !important;
  }
}

.to-flex-1400{

  @media only screen and (max-width: 1355px) {
    display: flex !important;
    justify-content: center;
    margin: 0 auto;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}


.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: 0.2s;
  z-index: 1000 !important;

  .navbar {
    height: 5vh;
  }
}

// .navbar-active .navbar_image-img {

//   @media only screen and (max-width: 1200px) {
//     height: 9rem;
//   }

//   @media only screen and (max-width: 768px) {
//     height: 6rem;
//   }

//   @media only screen and (max-width: 480px) {
//     height: auto;
//   }
// }

.before-white{

  &::before{
    color: #fff !important;
  }
}

.Navbar_home {
  padding: 1.2rem 0;
  margin-top: .5rem;

  @media only screen and (max-width: 990px) {
    padding: 0;
  }
}

.navbar_home-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: 0.2s;
  z-index: 1000 !important;
}

.m-l-10{
  margin-left: 1rem;

  @media only screen and (max-width: 990px) {
    margin-left: 12rem;
  }

  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

.p-l-2{
  margin-left: .5rem !important;

  @media only screen and (max-width: 990px) {
    margin-left: 0 !important;
  }
}

.p-r-2{
  margin-right: .5rem !important;

  @media only screen and (max-width: 990px) {
    margin-right: 0 !important;
  }
}

.navbar {
  

  &_toggler {
    background-color: var(--color-primary) !important;
    padding: .5rem;
    color: #fff !important;

    &-icon {
      background-color: var(--color-primary) !important;
      color: #fff !important; 
      font-size: 2.4rem;
      padding: .7rem 1rem;
      border-radius: .5rem;

      @media only screen and (max-width: 768px) {
        font-size: 2rem;
        padding: .5rem .7rem;
      }

      @media only screen and (max-width: 480px) {
        font-size: 1.6rem;
        padding: .5rem;
      }
    }
  }

  &_collapse{
    @media only screen and (max-width: 990px) {
      position: absolute !important;
      top: 100% !important;
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
    }
  }

  &_image{

    &-img{
      width: 100%;
      min-width: 17rem;
      max-width: 100%;
      height: auto;

      @media only screen and (max-width: 1200px) {
        height: auto;
      }

      @media only screen and (max-width: 990px) {
        height: 6rem;
        min-width: 23rem;
      }

      // @media only screen and (max-width: 768px) {
      //   height: 7rem;
      // }
    
      @media only screen and (max-width: 480px) {
        height: 5rem;
        min-width: 18rem;
      }

      @media only screen and (max-width: 360px) {
        height: 100%;
        min-width: 10rem;
      }
    }
  }

  &_nav {

    @media only screen and (max-width: 990px) {
      background-color: #fff !important;
      padding: 2rem !important;
      margin-top: .5rem !important;
      border-top: .1rem solid #09739f;
      z-index: 100;
    }

    &-ul {
      list-style: none;
    }

    &-li {
      display: inline-block;
      margin: 0 .2rem;
      position: relative;

      @media only screen and (max-width: 1200px) {
        margin: 0 .5rem  !important;
      }

      @media only screen and (max-width: 990px) {
        margin: .5rem .2rem  !important;
      }

      &--link {
        color: #0a103c !important;
        font-family: "Open Sans", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        white-space: nowrap;
        text-transform: capitalize !important;

        @media only screen and (max-width: 1200px) {
          font-size: 1.4rem;
        }

        &:hover {
          color: var(--color-secondary);
          transition: .2s all ease-in-out;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--p{
        color: #0a103c !important;
        font-family: "Open Sans", sans-serif;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        white-space: nowrap;
        text-transform: capitalize !important;
      }

      &--icon{
        color: #0a103c !important;
        font-family: "Open Sans", sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        white-space: nowrap;
        text-transform: capitalize !important;
      }

      &--link2 {
        color: #0a103c !important;
        font-family: "Open Sans", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize !important;

        &:hover {
          color: var(--color-secondary);
          transition: .2s all ease-in-out;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--btn {
        font-family: "Open Sans", sans-serif;
        color: #fff !important;
        font-style: normal;
        padding: .7rem 1.5rem !important;
        font-size: 1.4rem !important;
        transition: .3s;
        border-radius: 0;
        background-color: #0a103c;
        border-color: #0a103c;
        position: relative;
        border-style: solid;
        white-space: nowrap !important;
        text-transform: none !important;

        @media only screen and (max-width: 1200px) {
          padding: .7rem .5rem !important;
        }

        // @media only screen and (min-width: 1920px) {
        //   padding: 2rem 1.5rem !important;
        //   margin: .3rem 0 !important;
        // }


        &:hover {
          background-color: var(--color-secondary) !important;
          border-color: var(--color-secondary) !important;
          color: #fff !important;
          transition: .2s all ease-in-out;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.to-between{
  justify-content: space-between !important;

  @media only screen and (max-width: 990px) {
    justify-content: space-between !important;
  }
}

.to-100-per-990{

  @media only screen and (max-width: 990px) {
    max-width: 100% !important;
  }
}

.to-100-per-768{

  @media only screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.slider {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),  url("../img/slider.jpeg");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(54, 54, 54, 0.2) !important;
  //   z-index: -1;
  //   transition: 0.5s;
  // }

  &_content {
    position: relative;
    padding: 5rem 0;
    z-index: 100 !important;

    @media only screen and (min-width: 2200px) {
      padding: 5rem 0; /* Reduced from 7rem 0 */
    }
  
    @media only screen and (max-width: 1600px) {
      padding: 5rem; /* Reduced from 7rem */
    }
  
    @media only screen and (max-width: 1200px) {
      padding: 3rem 2rem; /* Reduced from 5rem 2rem */
    }
  
    @media only screen and (max-width: 1100px) {
      padding: 3rem .5rem; /* Reduced from 5rem .5rem */
    }
  
    @media only screen and (max-width: 1000px) {
      padding: 3rem 0; /* Reduced from 5rem 0 */
    }
  
    @media only screen and (max-width: 990px) {
      padding: 3rem 2rem; /* Reduced from 5rem 2rem */
    }
  
    @media only screen and (max-width: 768px) {
      padding: 3rem 0rem; /* Reduced from 5rem 0rem */
    }

    &-inner{
      width: 90%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &-label {
      color: #000;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      font-size: 1.8rem;
    }

    &-title {
      font-family: "Open Sans", Sans-serif;
      font-size: 3.6rem;
      font-weight: 400;
      color: #fff;
      line-height: 1.1;
      margin-bottom: 3rem;

      @media only screen and (max-width: 990px) {
        margin-top: 7rem;
        text-align: center;
      }


      // @media only screen and (max-width: 990px) {
      //   font-size: 6.5rem;
      // }

      // @media only screen and (max-width: 768px) {
      //   font-size: 4rem;
      // }
    }

    &-p {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.8;
      font-family: "Open Sans", sans-serif;
      color: #fff;
    }
  }

  &_form {
    padding: 2rem;
    padding-bottom: 4rem !important;
    margin-top: -2rem;
    background-color: rgba(255,255,255,0.5);
    border-radius: .5rem;

    @media only screen and (max-width: 990px) {
      margin-top: 0;
    }

    &-form{
      margin-top: 1rem;
    }

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #0a103c;
      font-size: 2.5rem;
      font-weight: 700;
      text-align: center;
    }

    &-label {
      display: flex;
      justify-content: center;
      margin: .5rem auto;
      font-family: "Open Sans", sans-serif;
      font-size: 1.6rem;
      color: #696c7f;
      text-align: center;
      font-weight: 400;
    }

    &-input {
      padding: .7rem 1rem;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border: 1px solid #ccc;
      border-radius: .5rem;
      width: 100%;
      max-width: 100%;
      outline: none;
      height: 4.2rem;
    }

    &-textarea {
      padding: 1rem;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border-radius: .5rem;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      font-family: "Open Sans", sans-serif;
      border-radius: .5rem .5rem .5rem .5rem;
      background: var(--color-primary);
      border: .2rem none transparent;
      box-shadow: 0px 0px 0px -.7rem rgba(0, 0, 0, 0);
      padding: 1.7rem 1.5rem !important;
      white-space: nowrap;
      color: #fff !important;
      text-transform: none !important;
      border-radius: .5rem !important;

      @media only screen and (max-width: 1200px) {
        margin-right: 0 !important;
      }

      &:hover {
        background: var(--color-secondary) !important;
        color: #fff !important;
        transition: all .5s;
        cursor: pointer;
      }
    }
  }
}

.f-w-700{
  font-weight: 700 !important;
}


.section {
  padding: 7rem 21rem;

  @media only screen and (min-width: 2200px) {
    padding: 7rem 0;
  }

  @media only screen and (max-width: 1800px) {
    padding: 10rem 7rem;
  }


  @media only screen and (max-width: 1600px) {
    padding: 7rem;
  }


  @media only screen and (max-width: 1200px) {
    padding: 5rem 2rem;
  }

  @media only screen and (max-width: 1100px) {
    padding: 5rem .5rem;
  }

  @media only screen and (max-width: 1000px) {
    padding: 5rem 0;
  }

  @media only screen and (max-width: 990px) {
    padding: 5rem 2rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 5rem 0rem;
  }
}

.section-y {
  padding: 7rem 0;

  @media only screen and (min-width: 2200px) {
    padding: 7rem 0;
  }

  @media only screen and (max-width: 1800px) {
    padding: 7rem 0;
  }


  @media only screen and (max-width: 1600px) {
    padding: 7rem 0;
  }


  @media only screen and (max-width: 1200px) {
    padding: 5rem 0;
  }

  @media only screen and (max-width: 1100px) {
    padding: 5rem 0;
  }

  @media only screen and (max-width: 1000px) {
    padding: 5rem 0;
  }

  @media only screen and (max-width: 990px) {
    padding: 5rem 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 5rem 0rem;
  }
}


.menu{


  &_image{
    @media only screen and (max-width: 768px) {
      margin-bottom: 2rem;
    }

    &-img{
      width: 100%;
      max-width: 100%;
      height: auto;
      object-fit: cover;

      @media only screen and (max-width: 768px) {
        width: auto;
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }

    &-img2{
      display: flex;
      justify-content: center;
      width: auto;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
      margin: 0 auto;
    }

    &-img3{
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
      margin: 0 auto;
    }

    &-img4{
      width: auto;
      height: 100%;
      object-fit: cover;
      height: 25rem;

      @media only screen and (max-width: 990px) {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  &_content{
    margin-bottom: 5rem;
    @media only screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    &-header{
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: .01em;
    }

    &-header2{
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-align: left;
      letter-spacing: .01em;
    }

    &-header3{
      font-family: "Open Sans", sans-serif;
      display: block;
      width: 100%;
      padding: 0;
      margin-bottom: 2rem;
      font-size: 2.1rem;
      line-height: inherit;
      color: #333;
      border: 0;
      border-bottom: 1px solid #e5e5e5;
    }

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 2.8rem;
      font-weight: 400;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: .01em;

      @media only screen and (max-width: 768px) {
        font-size: 2.8rem;
      }
    }

    &-title2{
      font-family: "Open Sans", sans-serif;
      color: #333;
      font-size: 2.8rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: .01em;

      @media only screen and (max-width: 768px) {
        font-size: 2.4rem;
      }
    }

    &-ul{
      list-style: none;
      padding-left: 5rem;

      // @media only screen and (max-width: 768px) {
      //   padding-left: 1rem;
      //  }
    }

    &-ul2{
      list-style: none;
      padding-left: 2.7rem;

      @media only screen and (max-width: 768px) {
        padding-left: 1rem;
       }
    }

    &-p{
      font-family: "Open Sans", sans-serif;
      padding-bottom: .5rem;
      position: relative;
      list-style: none;
      font-family: "Open Sans", sans-serif;
      color: #000;
      line-height: 1.7;
      font-weight: 400;
      font-size: 1.5rem;
    }

    &-li2{
      padding-bottom: 0;
      position: relative;
      list-style: none;
      font-family: "Open Sans", sans-serif;
      color: #000;
      line-height: 1.7;
      font-weight: 400;
      font-size: 1.5rem;
    }

    &-li{
      padding-bottom: 0;
      position: relative;
      list-style: none;
      font-family: "Open Sans", sans-serif;
      color: #8f8f8f;
      line-height: 1.7;
      font-weight: 400;
      font-size: 1.5rem;

      &::before{
        position: absolute;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        top: 0;
        left: -2.4rem;
        z-index: 0;
        font-size: 1.4rem;
        color: #000;
        content: "\f058";
        text-align: center;

        @media only screen and (max-width: 768px) {
          left: -1.7rem;
       }
      }

      &--link{
        padding-bottom: .5rem;
        position: relative;
        list-style: none;
        font-family: "Open Sans", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 1.5rem;
  
        &::before{
          position: absolute;
          font-family: "Font Awesome 5 Free";
          display: inline-block;
          top: 0;
          left: -2.4rem;
          z-index: 0;
          font-size: 1.4rem;
          color: #000;
          content: "\f058";
          text-align: center;

          @media only screen and (max-width: 768px) {
            left: -1.7rem;
         }
        }

        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--link3{
        padding-bottom: .5rem;
        position: relative;
        list-style: none;
        font-family: "Open Sans", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 1.4rem;
  
        &::before{
          position: absolute;
          font-family: "Font Awesome 5 Free";
          display: inline-block;
          top: 0;
          left: -2.4rem;
          z-index: 0;
          font-size: 1.4rem;
          color: #000;
          content: "\f058";
          text-align: center;

          @media only screen and (max-width: 768px) {
            left: -1.7rem;
         }
        }

        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--link2{
        padding-bottom: .5rem;
        position: relative;
        list-style: none;
        font-family: "Open Sans", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 1.5rem;


        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    &-link{

      @media only screen and (max-width: 990px) {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
      }

      &--a{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        font-family: "Open Sans", sans-serif;
        border-radius: .5rem .5rem .5rem .5rem;
        background: var(--color-primary);
        border: .2rem none transparent;
        box-shadow: 0px 0px 0px -.7rem rgba(0, 0, 0, 0);
        padding: 1.5rem 2.5rem !important;
        margin-right: 0 !important;
        white-space: normal !important;
        display: block !important;
        text-align: center !important;
        color: #fff !important;
        text-transform: none !important;
        border-radius: .3rem !important;
        text-align: center !important;
  
        @media only screen and (max-width: 1200px) {
          margin-right: 0 !important;
          white-space: normal !important;
          display: block !important;
          text-align: center !important;
        }

        @media only screen and (max-width: 580px) {
          display: inline !important;
        }
  
        &:hover {
          text-decoration: none;
          background: var(--color-secondary) !important;
          color: #fff !important;
          transition: all .5s;
          cursor: pointer;
        }
      }
    }
  }

  &_counters{
    padding: 3rem 0;

    &-title{
      color: #fff;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 5.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: -1.2px;
      white-space: nowrap
    }

    &-p{
      color: #fff;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
    }
  }

  &_box{

    &-image{
      position: relative;

      &::after{
        content: '';
        position: absolute;
        z-index: -1;
        left: -9px;
        bottom: -4px;
        width: 40px;
        height: 40px;
        background-color: #f2f2f2;
        transition: .33s all linear;
      }

      &:hover{
        &::after{
          left: 0;
          bottom: 0;
          width: 25%;
          height: 100%;
        }
      }
    }

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 1.8rem;
      font-weight: 500;
      margin: 1rem 0;
    }

    &-p{
      font-family: "Open Sans", sans-serif;
      font-size: 1.4rem;
      color: #747474;
      line-height: 1.7;
      text-align: justify;


      @media only screen and (max-width: 768px) {
        font-size: 1.8rem;
      }
    }
  }
}

.br{
  border-radius: 1rem;
}

.after-line{
  position: relative;

  &::after{
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background: #0a103c;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.to-show-990{
  display: none;

  @media only screen and (max-width: 990px) {
    display: block !important;
  }
}

.stripe{
  background: #12394b;

  &_content{
    padding: 4rem 0;

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: .01em;

      @media only screen and (max-width: 480px) {
        font-size: 2rem;
      }
    }
  }
}



.accordion {
  .accordion-item {
    position: relative;
    background: #333 !important;
    color: #fff;
    margin-bottom: 2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    border: .5rem solid #da4;
    
    .accordion-title {
      position: relative;
      color: #fff !important;
      font-size: 1.8rem;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      padding: 1rem 1.5rem;

      @media only screen and (max-width: 1200px) {
        white-space: normal !important;
        display: flex;
     }

      // &::before{
      //   content: '\f068';
      //   color: #333;
      //   background-color: #fff;
      //   border-radius: 50%;
      //   font-style: normal;
      //   font-weight: 500;
      //   width: 2.5rem;
      //   height: 2.5rem;
      //   font-size: 1.5rem;
      //   line-height: 1.44;
      //   border-radius: 50%;
      //   font-family: "Font Awesome 5 Free";
      //   right: 2.4rem;
      //   position: absolute;
      //   top: 1rem;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   text-align: center;
      // }


      &[aria-expanded="true"]::before {
        content: '\f068';  // Change to minus icon when expanded
      }

      .card-arrow {
        content: '\f068';
        color: #333;
        background-color: #fff;
        border-radius: 50%;
        font-style: normal;
        font-weight: 500;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.5rem;
        line-height: 1.44;
        border-radius: 50%;
        font-family: "Font Awesome 5 Free";
        right: 2.4rem;
        position: absolute;
        top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;;
        // transform: rotate(45deg);
        transition: transform 0.3s ease;
      }
      
      &[aria-expanded="true"] .card-arrow {
        transform: rotate(-135deg);
      }
    }
    
    .accordion-body {
      background-color: #fff;
      padding: 1.5rem;
      border-top: none;
      padding-top: 2rem;
    }
  }
}

.section-gallery {
  padding: 60px 0;

  .section-title {
    font-family: "Open Sans", sans-serif; 
    font-size: 2em;
    margin-bottom: 20px;
  }

  .carousel {
    .item {
      position: relative;
      overflow: hidden;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease;
        color: #fff;

        h3 {
          color: #000;
          letter-spacing: .05em;
          font-size: 2rem;
          text-transform: uppercase;
        }
      }

      img {
        width: 100%;
        height: auto;
        transition: transform 0.3s ease;
        z-index: 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(54, 54, 54, 0.5) !important;
          z-index: -1;
          transition: 0.5s;
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}


.testimonial{

  @media only screen and (max-width: 990px) {
    margin-top: 4rem !important;
  }

  &_intro{
    font-family: "Open Sans", sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 .5rem 2rem -.6rem rgba(0, 0, 0, .4);
    position: relative;
    padding: 7rem 2rem;
    background: var(--color-primary);
    border-radius: .2rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  &_icon{
    width: 12rem;
    height: 12rem;
    border-radius: inherit;
    background: var(--color-secondary);
    -webkit-box-shadow: 0 .5rem 2.3rem -.6rem rgba(0, 0, 0, .4);
    box-shadow: 0 .5rem 2.3rem -6p.6rem rgba(0, 0, 0, .4);
    position: absolute;
    top: -5rem;
    left: -4rem;
    line-height: 12rem;
    text-align: center;
    color: #fff;
    font-size: 3rem;

    @media only screen and (max-width: 768px) {
      // width: 7rem;
      // height: 7rem;
      // font-size: 2.4rem;
      //left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  }

  &_item{

    @media only screen and (max-width: 768px) {
     margin-top: 2rem;
    }

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #333;
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
}

.bg-light-grey{
  background-color: #f5f6fa !important;
}

.text-to-right{
  text-align: right !important;
  font-size: 2.4rem !important;
}

.nos-pages{

  @media only screen and (max-width: 768px) {
    //margin-left: 5rem !important;
    margin: 1rem 0 !important;
   }

  //  @media only screen and (max-width: 480px) {
  //   margin-left: 3rem !important;
  //  }
}

.service{

  &-box{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3rem 0;
    min-height: 100%;
    transition: all .2s ease;
    z-index: 1;
  }

  &-icon{
    font-size: 3.2rem;
    text-align: center;
    color: #000
  }
}

.text-shad{
  text-shadow: 1px 1px 2px black;
}

.bgrey{
  background: #f9f9f9;
}

.item{
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3) !important;
    transition: 0.5s;
  }
}

.color-black{
  color: #000 !important;
  background-color: #000 !important;
}

.to-14{
  font-size: 1.4rem !important;
}

.footer{
  position: relative;
  background-image: url("../img/footer-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  color: #000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);

  @media only screen and (max-width: 768px) {
    padding: 1.9rem;
 }

  &-logo{
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      margin-bottom: 1rem;
   }
  }

  &-icon{
    font-size: 1.4rem;
    padding-right: .8rem;
    margin-top: .5rem;
  }
}

.footer-contact-wrapper{
  display: flex;
  margin: 1rem 0;
}

.text-black{
  font-family: "Open Sans", sans-serif;
  color: #000 !important;
}

.banner{
  position: relative;
  background-image: url("../img/banner.jpeg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(54, 54, 54, 0.2) !important;
    z-index: -1;
    transition: 0.5s;
  }

  &-2{
    background-color: #000;
  }


  &_content{
    padding: 7.5rem 0;

    &-title{
      font-family: "Open Sans", sans-serif;
      color: #fff;
      font-size: 4.8rem;
      font-weight: 500;
      line-height: 1.1;
      text-align: center;

      &::before{
        content: '';
        display: block;
        width: 9rem;
        height: 2px;
        margin: auto;
        margin-bottom: 1.5rem;
        background: #fff;
      }
    }
  }
}

.to-four-rem{
  font-size: 4rem !important;
  color: #000;
  font-weight: 600 !important;
}

.bg-trans{
  background: transparent !important;
}

.count-wrapper {
  padding: 3rem;
  background: #fff;
  border-radius: .5rem;
  -webkit-box-shadow: .2rem .3rem 1rem 0 rgba(0, 0, 0, .05);
  box-shadow: .2rem .3rem 1rem 0 rgba(0, 0, 0, .05);
  margin-bottom: 4rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    right: auto;
    bottom: auto;
    left: -5px;
    width: 3.3rem;
    height: 3.3rem;
    border-left: 0 solid transparent;
    border-right: 3.3rem solid transparent;
    border-top: 3.3rem solid #da4;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translate(-10px, -10px);
    -moz-transform: translate(-10px, -10px);
    -ms-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }

  &::after {
    content: "";
    position: absolute;
    top: auto;
    right: -5px;
    bottom: -5px;
    left: auto;
    width: 33px;
    height: 33px;
    border-left: 33px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 33px solid #da4;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translate(10px, 10px);
    -moz-transform: translate(10px, 10px);
    -ms-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  &:hover {
    &::before,
    &::after {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}


.count{
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #212121;
  font-size: 3.5rem;
}

.sign{
  font-weight: 900;
  color: #212121;
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
}

.chiffre h3 {
  font-size: 1.6rem;
  font-weight: 500;
  color: #da4;
  font-family: "Open Sans", sans-serif;
  margin: 1.5rem 0 0;
}

.tabs {
  &_ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    &-li {
      margin-top: .2rem;
      margin-left: 0;

      &--link {
        font-family: "Open Sans", sans-serif;
        position: relative;
        display: block;
        padding: 1rem 1.5rem;
        color: #000;
        font-size: 1.6rem;
        background-color: #fff;
        transition: background-color .3s, color .3s;

        &::before {
          position: absolute;
          display: inline-block;
          top: 50%;
          right: 4%;
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
          z-index: 0;
          font-size: 1.4rem;
          color: #fff;
          content: "\f054";
          text-align: center;
          font-family: "Font Awesome 5 Free";
        }
      }

      &.active .tabs_ul-li--link {
        background-color: #da4;
        color: #fff;
      }
    }
  }
}

.tab-content {
  .tab-pane {
    display: none;
    opacity: 0;
    transition: opacity .3s;

    &.active {
      display: block;
      opacity: 1;
    }
  }
}


.tabs_ul-li--link .active{
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.contact {
  padding: 10rem 0;
  position: relative;
  margin-left: 4rem;
  @media (max-width: 960px) {
    margin-left: 1rem;
  }

  &_header {
    &-title {
      font-family: "Open Sans", sans-serif;
      margin-bottom: 4rem;
      color: #000;
      line-height: 1.3;
      font-size: 3.6rem;
      font-weight: 900;
    }
  }

  &_content {
    width: 100%;
    max-width: 100%;
    // margin-left: -5rem !important;

    @media only screen and (max-width: 768px) {
      margin-bottom: 3rem;
    }

    &-form {
      width: 100%;
      max-width: 100%;
      position: relative;

      &--label {
        font-family: "Open Sans", sans-serif;
        display: block !important;
        color: #424242;
        font-size: 1.3rem;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-weight: 400;
      }

      &--input {
        font-family: "Open Sans", sans-serif;
        width: 100%;
        max-width: 100%;
        padding: 0.6rem 1.2rem;
        box-shadow: none;
        border: 0;
        height: 4.2rem;
        border: .1rem solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        border-radius: 0.5rem;
        font-size: 1.4rem;
        line-height: 1.4;
        color: #555;
        display: block;
      }

      &--textarea {
        display: block;
        max-width: 100% !important;
        border: 1px solid #ccc !important;
        color: #555 !important;
        font-family: "Open Sans", sans-serif;
        font-size: 1.4rem !important;
        line-height: 1.4;
        padding: 18px 2rem !important;
        outline: none !important;
        margin-bottom: 20px !important;
        width: 100% !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      }

      &--btn {
        font-family: "Open Sans", sans-serif;
        position: relative;
        background-color: var(--color-primary);
        color: #fff;
        text-align: center;
        position: relative;
        display: inline-block;
        padding: 1.2rem 2.5rem;
        margin: 1rem 0;
        transition: 0.3s;
        outline: 0;
        border-radius: 5rem;
        border: 0;
        font-size: 1.6rem;

        &:hover {
          background-color: var(--color-secondary);
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
      // #contact-form-response {
      //  text-align: center;
      //   background-color: #dff0d8;
      //   padding: 0.5rem;
      //   color: #3c763d;
      //   position: absolute;
      //   top: -14rem;
      //   left: 20%;
      //   width: 80vw;
      //   height: 3rem;
      //   border: 1px solid #d6e9c6;
      //   border-radius: 5px;
      //   display: none;
      // }
    }
  }
  &_info {
    display: flex;
    align-items: center;

    &-icon {
      display: inline-block;
      flex-shrink: 0;
      height: 9rem;
      width: 9rem;
      border-radius: 50%;
      text-align: center;
      line-height: 9rem;
      background: var(--color-secondary);
      color: #fff;
      font-size: 3rem;
    }

    &-content {
      display: inline-block;
      padding-left: 2rem;

      &--title {
        font-family: "Open Sans", sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #000;
        margin: 1.5rem 0 1rem;
        line-height: 1.2;
      }

      &--p {
        font-family: "Open Sans", sans-serif;
        color: #747474;
        font-size: 1.4rem;
        text-align: justify;
        line-height: 1.7;
        font-weight: 400;
        @media (max-width: 500px) {
          font-size: 1.2rem;
        }
      }

      &--link {
        font-weight: 400;
        color: #333;
        @media (max-width: 500px) {
          font-size: 1.2rem;
        }

        &:hover {
          transition: .2s all ease-in-out;
          color: var(--color-primary);
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.text-grey-black{
  color: #0a103c !important;
}


.mentions{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8rem 0;
  .text{
    color: #000;
    font-size: 1.5rem;
    line-height: 1.7;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    width: 100%;
  }
  .wd{
    width: 100%;
  }
  .pad{
    padding: 1rem 0;
  }
  .content-wrap{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 4rem;
    border: .5rem solid #c93;
    .txt{
    padding: 1rem 1rem;
    font-size: 16px;
    width: 100%;
    background-color: #000;
    color: white;

    // &:hover{
    //   text-decoration: none;
    //   color: var(--color-secondary) !important;
    //   cursor: pointer;
    // }
    }
    .bottom{
      padding: 2.5rem 1rem;
      border: 1.5px solid rgb(218, 214, 214);
      width: 100%;
      #heading{
        font-family: "Open Sans", sans-serif;
        text-align: left;
        font-size: 28px !important;
        margin-bottom: 2rem;
      }
    }
  }
}

.link{
  color: #333 !important;

  &:hover{
    transition: .3s all ease-in-out;
    color: var(--color-secondary) !important;
    cursor: pointer;
  }
}

.link2{
  color: #333 !important;

  &:hover{
    text-decoration: none;
    transition: .3s all ease-in-out;
    color: #696c7f !important;
    cursor: pointer;
  }
}

.active-clr {
  color: var(--color-secondary) !important;
}


.page404{

  &_content{
    padding: 2rem 0;

    &-title{
      color: #333;
      font-family: "Open Sans", sans-serif;
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 400;
    }

    &-p{
      color: #747474;
      font-family: "Open Sans", sans-serif;
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 52.5px */
      letter-spacing: 0.35px;
      margin: 2rem 0;
    }

    &-link{
      color: #000;
      font-family: "Open Sans", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 52.5px */
      letter-spacing: 0.35px;

      &:hover{
        transition: .2s all ease-in-out;
        text-decoration: none;
        color: var(--color-secondary);
        cursor: pointer;
      }
    }
  }

}

.error {
  color: red;
  font-size: 1.4rem;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fixphone {
  position: fixed;
  z-index: 10000 !important;
  bottom: 0;
  right: .5rem;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #FFFFFF;

  @media only screen and (max-width: 990px) {
    display: none;
  }


  @media (max-width: 400px) {
    bottom: 0 !important;
    right: 0 !important;
  }

  .button {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 2rem;
    margin-right: 1rem;
    padding: 1.3rem 2.5rem;
    border-radius: 1.5rem;
    color: #fff;
    background-color: var(--color-primary);
    display: inline-block;
    border: unset;
    transition: all 0.5s;

    @media (max-width: 400px) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }

    &:hover {
      text-decoration: none;
      background: #fff;
      border: 2px solid var(--color-primary);
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}

.underline {
  text-decoration: underline !important;
}


#fixphone {
  font-family: "Open Sans", sans-serif;
  position: fixed;
  bottom: 14%;
  right: 1.5rem;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
  font-size: 1.8rem;
  padding: .5rem 1rem;
  border-radius: .5rem;
  transition: 0.3s;
  text-decoration: none;
  z-index: 10000 !important;

  @media only screen and (max-width: 768px) {
    bottom: 3%;
  }
}

#fixphone:hover {
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary);
  text-decoration: none;
}

.success-message {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  color: rgb(51, 200, 51);
}

.error-message {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  color: red;
}

#filters {
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  margin-bottom: 5rem;
}

#filters li {
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  cursor: pointer;
  padding-right: 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: #151515;
  transition: .3s all ease-in-out;
}

#filters .filter.active {
  color: var(--color-secondary);
  border-radius: 5px;
}

.portfolio {
  margin-bottom: 3rem;
}

#portfoliolist .portfolio {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  display: none;
  float: left;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}

.owl-dots {
  text-align: center !important; /* Center the dots */
  margin-top: 1rem !important; /* Add some space above the dots */
}

.owl-dot {
  display: inline-block !important; /* Display dots inline */
  width: 1rem !important; /* Width of each dot */
  height: 1rem !important; /* Height of each dot */
  margin: .5rem !important; /* Space between dots */
  background-color: #d6d6d6 !important; /* Default background color */
  border-radius: 50% !important; /* Make dots circular */
  transition: background-color 0.3s ease !important; /* Smooth transition for background color */
}

.owl-dot span {
  display: none !important; /* Hide default span inside dot */
}

.owl-dot.active {
  background-color: #869791 !important; /* Background color for active dot */
}

.owl-dot:hover {
  background-color: #869791 !important; /* Background color when dot is hovered */
}

#owl .owl-next{
  display: none !important;
}

#owl .owl-prev{
  display: none !important;
}


.len-chk{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80% !important;
}

.to-14{
  font-size: 1.4rem !important;
}

.modal-body {
  position: relative;
  padding: 2rem !important;
  font-size: 1.6rem !important;
  font-family: 'Open Sans', sans-serif;
}

.modal-footer {
  justify-content: center !important;
  margin-top: 1.5rem !important;
  padding: 1.9rem 2rem 2rem !important;
  text-align: center;
  border-top: .1rem solid #e5e5e5 !important;
}

.btn-modal{
  background-color: #428bca !important;
  padding: .6rem 1.2rem !important;
  font-size: 1.4rem !important;
}

.to-column-1920{

  @media only screen and (min-width: 1920px) {
    flex-direction: column !important;
  }
}

.to-start-1920{
  align-items: center !important;
  // @media only screen and (min-width: 1920px) {
  //   align-items: flex-start !important;
  // }
}

.m-to-3{
  margin-top: .3rem !important;

  @media only screen and (min-width: 1600px) {
    margin-top: 0 !important;
  }
}

.to-show-1600 {
  display: none !important;

  @media only screen and (min-width: 1600px) {
    display: flex !important;
    margin-left: -6rem !important;
  }

  @media only screen and (min-width: 1920px) {
    flex-direction: column;
    margin-left: 0 !important;
  }
}

.to-hide-1600 {
  display: flex !important;

  @media only screen and (min-width: 1600px) {
    display: none !important;
  }
  
}

// .to-col-1920{
//   display: flex !important;

//   @media only screen and (min-width: 1920px) {
//     display: none !important;
//   }
// }

// .to-col-1920-{
//   display: none !important;

//   @media only screen and (min-width: 1920px) {
//     display: flex !important;
//     flex-direction: column;
//   }
// }

@media screen and (max-width: 576px) {
  .BurialCremation .MonumentTypes {
    height: 100% !important
  }
}


// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item{
    color: $body-color!important;
    &.active{
      color: $white!important;
    }
  }
}
